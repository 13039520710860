<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-app id="app">
      <!-- <AppHeader /> -->
      <router-view
        style="min-height: 80vh"
        :authentication="authentication"
      />
      <AppFooter v-if="$route.name !== 'login'"/>
    </v-app>
  </div>
</template>
<script>
// import axios from "axios";

// import AppHeader from "./components/Common/appHeader";
import AppFooter from "./components/Common/appFooter";
import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
import Socket from "./Sockets/socket";

export default {
  components: {
    // AppHeader,
    AppFooter,
  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      authentication: false,
      ServerError: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
    };
  },
  mounted() {
    this.socketAuth();
  },
  computed: {
    authValue() {
      return this.$store.state.authentication;
    },
  },
  watch: {
    authValue() {
      if (this.authValue == false) this.socketAuth();
    },
  },

  beforeMount() {
    // if (typeof localStorage.getItem("token") == "string") {
    //   // this.$store.commit("appLoading", true);
    //   axios({
    //     method: "POST",
    //     url: "/user/me",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         // this.$store.commit("appLoading", false);
    //         this.$store.commit("userData", response.data.data);
    //         // this.$store.commit("userRole", response.data.role);
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       // this.$store.commit("appLoading", false);
    //       console.log(err);
    //     });
    // }
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
  methods: {
    socketAuth() {
      Socket.authFunction(this); // <---read data
    },
  },
};
</script>
<style>
.sofiaProBold {
  font-family: sofiaProBold;
}
.sofiaProRegular {
  font-family: sofiaProRegular;
}
</style>