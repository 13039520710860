import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import store from './store'

import PageLoader from './components/Common/pageLoader'
import ImageLoader from './components/Common/imageLoader'
import ServerError from './components/Common/500'
import Logo from './components/Common/logo'
import SnackBar from './components/Common/snackBar'
import VueElementLoading from 'vue-element-loading'
import Viewer from 'v-viewer'
import Vue2Editor from "vue2-editor";
import JsonExcel from "vue-json-excel";
// import 'apexcharts/dist/apexcharts.min'
import VueApexCharts from 'vue-apexcharts'
import 'viewerjs/dist/viewer.css'
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import HighchartsVue from 'highcharts-vue';

Vue.use(HighchartsVue);
Exporting(Highcharts);


Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('VueElementLoading', VueElementLoading)
Vue.use(Viewer)
Vue.use(Vue2Editor);
Vue.component('PageLoader', PageLoader)
Vue.component('ImageLoader', ImageLoader)
Vue.component('ServerError', ServerError)
Vue.component('Logo', Logo)
Vue.component('SnackBar', SnackBar)
Vue.component('VueElementLoading', VueElementLoading)
Vue.component("downloadExcel", JsonExcel);
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)
window.axios = require('axios')

import VueTimeago from 'vue-timeago'

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})


// import VueSocketIO from 'vue-socket.io';
// import socket from 'socket.io-client'
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection:socket("http://192.168.53.162:3050"),
//   vuex: {
//     store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//   },
//   autoConnect:true

// }))



Vue.use(Vuex)

Vue.config.productionTip = false



window.axios = require('axios')


// axios.defaults.baseURL = 'http://localhost:3050'
// Vue.prototype.baseURL = "http://localhost:3050"
// Vue.prototype.mediaURL = "http://localhost:3050/u/"
// Vue.prototype.mediaURL1 = "http://localhost:3050/wp/"  

// axios.defaults.baseURL = 'http://192.168.54.191:3051'
// Vue.prototype.baseURL = "http://192.168.54.191:3051"
// Vue.prototype.mediaURL = "http://192.168.54.191:3051/u/"
// Vue.prototype.mediaURL1 = "http://192.168.54.191:3051/wp/"

axios.defaults.baseURL = 'https://sarpaodishaapi.leopardtechlabs.in'
Vue.prototype.baseURL = "https://sarpaodishaapi.leopardtechlabs.in"
Vue.prototype.mediaURL = "https://sarpaodishaapi.leopardtechlabs.in/u/"
Vue.prototype.mediaURL1 = "https://sarpaodishaapi.leopardtechlabs.in/wp/"


// axios.defaults.baseURL = 'http://192.168.52.49:3000'
// Vue.prototype.baseURL = "http://192.168.52.49:3000"


// axios.defaults.baseURL = 'http://13.127.237.46/';
// Vue.prototype.baseURL = "http://13.127.237.46/"
// Vue.prototype.mediaURL = "http://13.127.237.46/file/get/"
Vue.prototype.appColor="#FF6907"
new Vue({
  router,
  vuetify,
  // socket,
  store,
  render: h => h(App)
}).$mount('#app')